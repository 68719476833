import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import UniversitiesTable from "./UniversitiesTable";
import axios from "axios";

const AdminDashboard = () => {
 const [universities, setUniversities] = useState([]);

 const getData = async (page = 1, limit = 10) => {
  const url = `https://admission-backend-theta.vercel.app/api/universities?page=${page}&limit=${limit}`;

  try {
   const response = await axios.get(url);
   return response.data.universities;
  } catch (error) {
   if (error.response) {
    console.error("Server responded with error status:", error.response.status);
    console.error("Error response data:", error.response.data);
   } else if (error.request) {
    console.error("No response received from server:", error.request);
   } else {
    console.error("Error setting up the request:", error.message);
   }
   return null;
  }
 };

 useEffect(() => {
  const fetchData = async () => {
   try {
    const data = await getData();
    if (data) {
     setUniversities(data);
    }
   } catch (error) {
    console.error("Error fetching data:", error);
   }
  };

  fetchData();
 }, []);
 console.log("universities:", universities);
 return (
  <Box>
   <h1>Universities Data</h1>
   <UniversitiesTable data={universities} />
  </Box>
 );
};

export default AdminDashboard;
